<template>
    <div class="home-container">
        <div class="userlist-container">
            <div>
                <div class="video-chat-requests-header">
                    <div
                        class="collapse-header"
                        v-on:click="toggleCollapse(video_chat_request_list_obj)"
                    >
                        <div
                            class="collapse-header-triangle"
                            v-bind:class="{ turned: !video_chat_request_list_obj.collapsed }"
                        ></div>
                        Video Chat Requests
                    </div>
                    <VideoChatButton
                        v-bind:userID="this.$store.state.userId"
                        buttonText="Open Video Chat Window"
                        class="video-chat-button"
                        v-if="this.$store.getters.isRadiologist"
                    />
                </div>
                <VideoChatRequestList
                    class="video-request-list"
                    v-bind:class="{ opened: !video_chat_request_list_obj.collapsed }"
                ></VideoChatRequestList>
            </div>
            <div></div>
            <div class="collapse-header" v-on:click="toggleCollapse(recent_message_list_obj)">
                <div
                    class="collapse-header-triangle"
                    v-bind:class="{ turned: !recent_message_list_obj.collapsed }"
                ></div>
                Recent Text Messages
            </div>
            <RecentMessageList
                class="recent-message-list"
                v-bind:class="{ opened: !recent_message_list_obj.collapsed }"
            ></RecentMessageList>
            <div>
                <div
                    class="collapse-header"
                    v-bind:class="{ smallfont: this.$store.getters.isRadiologist }"
                    v-on:click="toggleCollapse(rad_list_obj)"
                >
                    <div
                        class="collapse-header-triangle"
                        v-bind:class="{ turned: !rad_list_obj.collapsed }"
                    ></div>
                    Radiologist List
                </div>
                <UserList
                    class="rad-user-list"
                    v-bind:class="{ opened: !rad_list_obj.collapsed }"
                    v-bind:show-title="false"
                    usertype="radiologists"
                    v-bind:fields="['name', 'statusText', 'phone']"
                    v-bind:show-text-chat-button="true"
                    v-bind:show-video-chat-button="true"
                ></UserList>
            </div>
            <div v-if="this.$store.getters.isRadiologist" class="online-users">
                <div v-for="location in locations" v-bind:key="location.name">
                    <div
                        class="collapse-header smallfont"
                        v-on:click="toggleCollapse(location)"
                    >
                        <div
                            class="collapse-header-triangle"
                            v-bind:class="{ turned: !location.collapsed }"
                        ></div>
                        <span v-if="location.name !== 'Other'">Hospital Techs -</span>
                        {{ location.name }}
                    </div>
                    <UserList
                        class="tech-user-list"
                        v-bind:class="{ opened: !location.collapsed }"
                        v-bind:show-title="false"
                        usertype="techs"
                        v-bind:fields="['name', 'status', 'phone', 'email']"
                        v-bind:location-filter="location.name"
                    ></UserList>
                </div>
            </div>
        </div>
        <div class="images-list">
            <ImageGallery />
        </div>
    </div>
</template>

<script>
import ImageGallery from "@/components/ImageGallery.vue";
import UserList from "@/components/UserList.vue";
import RecentMessageList from "@/components/RecentMessageList.vue";
import VideoChatRequestList from "@/components/VideoChatRequestList.vue";
import VideoChatButton from "@/components/VideoChatButton.vue";
import config from "@/config.js";

let initialLocations = [];
config.location_list.forEach(function (location) {
    initialLocations.push({
        name: location,
        collapsed: true,
    });
});

export default {
    name: "home",
    components: {
        UserList,
        VideoChatRequestList,
        VideoChatButton,
        RecentMessageList,
        ImageGallery,
    },
    data() {
        return {
            locations: initialLocations,
            rad_list_obj: {
                collapsed: false,
            },
            video_chat_request_list_obj: {
                collapsed: false,
            },
            recent_message_list_obj: {
                collapsed: false,
            },
            timer: "",
            timer_interval: 30000,
        };
    },
    created() {
        if (this.$store.getters.isRadiologist) {
            this.timer_interval = 10000;
            this.rad_list_obj.collapsed = true;
        }
        this.$store.dispatch("initUser");
        this.$store.dispatch("fetchPermissions");
        this.$store.dispatch("refreshUsers");
        this.$store.dispatch("getRecentMessages");
        this.$store.dispatch("getVideoChatRequests");
        this.timer = setInterval(() => {
            this.$store.dispatch("refreshUsers");
            this.$store.dispatch("getRecentMessages");
            this.$store.dispatch("getVideoChatRequests");
        }, this.timer_interval);
    },
    beforeDestroy() {
        clearInterval(this.timer);
    },
    methods: {
        toggleCollapse(location) {
            location.collapsed = !location.collapsed;
        },
    },
};
</script>

<style scoped>
.home-container {
    display: flex;
    flex-wrap: nowrap;
}

.online-users {
    order: 1;
    flex: 1 60%;
}

.images-list {
    order: 2;
    flex: 1 40%;
}

.video-request-list,
.recent-message-list,
.tech-user-list,
.rad-user-list {
    display: none;
    transition: all 0.25s ease-in-out;
}

.opened {
    display: block;
}

.userlist-container {
    display: flex;
    flex-direction: column;
    order: 0;
    flex: 1 60%;
}

.video-chat-button {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
}

.video-chat-requests-header {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.radiologist-list {
    border-top: 1px solid #999;
    padding: 0px;
    margin-bottom: 10px;
}

.collapse-header {
    user-select: none;
    text-align: left;
    font-size: 20px;
    font-weight: bold;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 15px;
    color: #42b983;
    cursor: pointer;
    display: inline-block;
}

.smallfont {
    font-size: 14px;
}

.collapse-header:hover {
    color: #56f0aa;
}

.collapse-header-triangle {
    content: " ";
    width: 0;
    height: 0;
    display: inline-block;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid #42b983;
    vertical-align: middle;
    margin-right: 0.7rem;
    transform: translateY(-3px);
    transition: transform 0.2s ease-out;
}

.collapse-header-triangle.turned {
    transform: rotate(90deg) translateX(-3px);
}

@media all and (max-width: 900px) {
    .images-list {
        display: none;
    }
}
</style>
