let config;

config = {
    rc_api_url: "https://chat.bra-communicator.com",
    bra_api_url: "https://api.bra-communicator.com",
    site_modes: ["tech", "rad"],
    radiologist_list: [
        "dougpeterson",
        "johnholmen",
        "lukeroller",
        "mikefortney",
        "mikeschirado",
    ],
    peer_review_rad_reviewer: [
        "Dr. Doug Peterson",
        "Dr. John Holmen",
        "Dr. Luke Roller",
        "Dr. Mike Fortney",
        "Dr. Mike Schirado",
    ],
    peer_review_rad_reviewed: [
        "Dr. Doug Peterson",
        "Dr. John Holmen",
        "Dr. Luke Roller",
        "Dr. Mike Fortney",
        "Dr. Mike Schirado",
        "Dr. Podoll",
        "Dr. Sirr",
        "Dr. Fogarty",
        "Dr. McIntee",
    ],
    fmo_audit_rad_list: [
        "BRA Group",
        "Dr. Doug Peterson",
        "Dr. John Holmen",
        "Dr. Luke Roller",
        "Dr. Mike Fortney",
        "Dr. Mike Schirado",
        "Dr. Podoll",
        "Dr. Sirr",
        "Dr. Fogarty",
        "Dr. McIntee",
    ],
    peer_review_scores: ["1", "2a", "2b", "3a", "3b", "4a", "4b"],
    modalities_list: ["RAD", "US", "CT", "MRI", "NM"],
    location_list: [
        "CHI St. Alexius Bismarck",
        "CHI Mandan",
        "CHI Garrison",
        "CHI Minot",
        "CHI Turtle Lake",
        "Ashley",
        "Bowman",
        "Elgin",
        "Harvey",
        "Hettinger",
        "Linton",
        "Sakakawea/Hazen",
        "Wishek",
        "Other",
    ],
    rad_status_list: [
        "At the Hospital - Reading All Studies",
        "Not at the Hospital - Reading CT/MR/US/NM Only",
        "NOT CURRENTLY READING - PLEASE SEND TO VRAD",
        "Not on Call",
    ],
};

export default config;
