<template>
    <div class="component-container">
        <div v-if="displayError" class="display-error-overlay">
            <div v-if="displayError" class="display-error">
                <span class="closebtn" v-on:click="closeError()">&times;</span>
                {{ displayError }}
                <router-link class="nav-link" to="/support">Contact Support</router-link>
            </div>
        </div>
        <div class="none-message" v-if="this.requestsLength == 0">
            No video chat requests from the last 24 hours.
        </div>
        <table v-if="this.requestsLength !== 0">
            <thead>
                <td>Name</td>
                <td>Time Requested</td>
                <td>Time Finished</td>
                <td><!-- Mark as finished button --></td>
                <td><!-- Open Video Chat Button --></td>
            </thead>
            <tr
                v-for="request in this.requests"
                v-bind:key="request.id"
                v-bind:class="{ unreadrequest: request.active == 1 }"
            >
                <td>{{ request.from_user_fullname }}</td>
                <td class="date-field">{{ getDateString(request.date_insert) }}</td>
                <td class="date-field">{{ getDateFinish(request) }}</td>
                <td>
                    <button
                        class="chat-button"
                        v-if="request.active == 1"
                        v-on:click="markInactive(request)"
                    >
                        Mark as Finished
                    </button>
                </td>
                <td>
                    <VideoChatButton
                        v-bind:userID="userID"
                        buttonText="Open Video Chat Window"
                        class="video-chat-button"
                    />
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
import VideoChatButton from "@/components/VideoChatButton.vue";
export default {
    name: "VideoChatRequests",
    components: {
        VideoChatButton,
    },
    data() {
        return {
            displayError: false,
        };
    },
    computed: {
        requests() {
            return this.$store.state.video_chat_requests;
        },
        requestsLength() {
            if (typeof this.$store.state.video_chat_requests == "undefined") {
                return 0;
            } else {
                return Object.keys(this.$store.state.video_chat_requests).length;
            }
        },
        userID() {
            return this.$store.state.userId;
        },
    },
    methods: {
        closeError() {
            this.displayError = false;
        },
        getDateString(in_date) {
            let date = new Date(in_date);
            var offset = new Date().getTimezoneOffset();
            date.setMinutes(date.getMinutes() - offset);
            return date.toLocaleTimeString() + "  (" + date.toLocaleDateString() + ")";
        },
        getDateFinish(request) {
            if (request.active == 1) {
                return "--";
            } else {
                return this.getDateString(request.date_update);
            }
        },
        getUserById(user_id) {
            let user_obj = this.$store.state.users.find(function (user) {
                return user._id == user_id;
            });
            return user_obj;
        },
        markInactive(request) {
            this.$store.dispatch("markVideoRequestInactive", { request_id: request.id });
        },
        openVideoChat(request) {
            if (typeof request.video_chat_link !== "undefined") {
                let a = document.createElement("a");
                a.target = "_blank";
                a.href = request.video_chat_link;
                a.click();
                this.markInactive(request);
            } else {
                this.displayError = "You do not have a Zoom account setup yet.";
            }
        },
    },
};
</script>

<style scoped>
table {
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;
    font-size: 14px;
    text-align: left;
}

table thead {
    font-size: 14px;
    font-weight: bold;
    border-bottom: 1px solid white;
    text-align: center;
}

table td,
table thead td {
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 8px;
    padding-right: 8px;
    text-align: center;
}

tr {
    border-bottom: 1px solid #999;
}

.unreadrequest {
    color: #09f;
    background: #202020;
}

tr:not(.unreadrequest) {
    color: #777;
    background: #151515;
}

.date-field {
    font-size: 12px;
    font-family: "Lucida Console", Monaco, monospace;
}

.request-text-field {
    white-space: break-spaces;
}

.chat-button {
    cursor: pointer;
    background: #214a80;
    border: none;
    border-radius: 5px;
    padding: 5px;
    box-shadow: none;
    text-shadow: none;
    outline: none;
    color: #fff;
    font-size: 12px;
}

.chat-button:hover {
    opacity: 0.8;
}

.chat-button:active {
    transform: translateY(1px);
}

.chat-button:focus {
    outline: none;
}

.chat-button::-moz-focus-inner {
    border: 0;
}

.display-error-overlay {
    position: absolute;
    z-index: 9999;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.display-error {
    font-size: large;
    margin: auto;
    padding: 20px;
    background-color: #e04545;
    color: #fff;
    border-radius: 4px;
}

.closebtn {
    margin-left: 25px;
    color: #fff;
    font-weight: bold;
    float: right;
    font-size: 30px;
    line-height: 20px;
    cursor: pointer;
    transition: 0.3s;
}

.closebtn:hover {
    color: black;
}

.none-message {
    padding: 15px;
}

@media all and (max-width: 1600px) {
    .chat-button {
        font-size: 10px;
    }
}
</style>
