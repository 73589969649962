<template>
    <div class="user-list">
        <div v-show="showTitle" class="table-header">{{ this.title }}</div>
        <table>
            <thead v-if="showHeader">
                <td v-if="showApproveButton"></td>
                <td v-if="showRejectButton"></td>
                <td v-for="header in fields" v-bind:key="header">{{ field_names[header] }}</td>
                <td v-if="showTextChatButton"></td>
                <td v-if="showVideoChatButton"></td>
            </thead>
            <tr
                v-for="user in this.users"
                v-bind:key="user._id"
                v-bind:class="[{ noBackground: noBackground }, user.status]"
            >
                <td v-if="showApproveButton">
                    <a class="approve-button" v-on:click="approveUser(user)">Approve</a>
                </td>
                <td v-if="showRejectButton">
                    <a class="reject-button" v-on:click="rejectUser(user)">Reject</a>
                </td>
                <td
                    v-for="field in fields"
                    v-bind:key="field"
                    v-bind:class="['user-' + field, getFieldColor(field, user.statusText)]"
                >
                    {{ getFieldValue(field, user) }}
                    <a
                        class="call-link"
                        v-if="
                            (field == 'phone' || field == 'altphone') &&
                            getFieldValue(field, user) != 'N/A'
                        "
                        :href="'tel:' + getFieldValue(field, user)"
                        >Call</a
                    >
                    <a
                        class="email-link"
                        v-if="field == 'email' && getFieldValue(field, user) !== ''"
                        :href="'mailto:' + getFieldValue(field, user)"
                        >Email</a
                    >
                </td>
                <td v-if="showTextChatButton">
                    <button class="chat-button" v-on:click="openTextChat(user)">
                        Text Chat with {{ user.name }}
                    </button>
                </td>
                <td v-if="showVideoChatButton && !isRadiologist()">
                    <VideoChatButton v-bind:userID="user._id" />
                </td>
                <td v-if="showVideoChatButton && isRadiologist()">
                    <VideoChatButton
                        v-bind:userID="currentUserID"
                        v-bind:messageToUser="user.username"
                    />
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
import VideoChatButton from "@/components/VideoChatButton.vue";
import config from "@/config.js";

export default {
    name: "UserList",
    components: {
        VideoChatButton,
    },
    props: {
        title: {
            type: String,
            default: "User List",
        },
        showTitle: {
            type: Boolean,
            default: true,
        },
        showHeader: {
            type: Boolean,
            default: true,
        },
        showTextChatButton: {
            type: Boolean,
            default: true,
        },
        showVideoChatButton: {
            type: Boolean,
            default: true,
        },
        showApproveButton: {
            type: Boolean,
            default: false,
        },
        showRejectButton: {
            type: Boolean,
            default: false,
        },
        showOnlyOnlineUsers: {
            type: Boolean,
            default: false,
        },
        noBackground: {
            type: Boolean,
            default: false,
        },
        radOnCallList: {
            type: Boolean,
            default: false,
        },
        maxResults: {
            type: Number,
            default: 0,
        },
        usertype: {
            type: String,
            default: "all",
            validator: function (value) {
                return ["all", "radiologists", "techs"].indexOf(value) !== -1;
            },
        },
        locationFilter: {
            type: String,
            default: "all",
            validator: function (value) {
                return config.location_list.concat("all").indexOf(value) !== -1;
            },
        },
        inputUsers: {
            type: Array,
            default: function () {
                return [];
            },
        },
        showOnlyInputUsers: {
            type: Boolean,
            default: false,
        },
        fields: {
            type: Array,
            default: function () {
                return [
                    "name",
                    "location",
                    "status",
                    "statusText",
                    "phone",
                    "altphone",
                    "pager",
                    "email",
                ];
            },
        },
    },
    data() {
        return {
            field_names: {
                name: "Name",
                status: "Status",
                location: "Location",
                phone: "Phone #",
                altphone: "Alt. Phone #",
                pager: "Pager",
                email: "Email",
                statusText: "Status Message",
            },
        };
    },
    computed: {
        currentUserID() {
            return this.$store.state.userId;
        },
        users() {
            // if input users, show them only
            if (Array.isArray(this.inputUsers) && this.showOnlyInputUsers) {
                return this.inputUsers;
            }

            let userlist = [];

            // Filter by user type
            if (this.usertype == "radiologists") {
                userlist = this.$store.getters.getRadiologists;
            } else if (this.usertype == "techs") {
                userlist = this.$store.getters.getTechs;
            } else {
                userlist = this.$store.state.users;
            }

            // Filter by user location
            if (this.locationFilter !== "all") {
                userlist = userlist.filter(function (user) {
                    return user.customFields.location == this;
                }, this.locationFilter);
            }

            // Filter by user online/offline
            if (this.showOnlyOnlineUsers) {
                userlist = userlist.filter(function (user) {
                    return user.status == "online";
                });
            }

            if (this.radOnCallList) {
                userlist = userlist.filter(function (user) {
                    return (
                        user.statusText.toLowerCase() !== "offline" &&
                        user.statusText !== "Not on Call"
                    );
                });
            }

            // Trying to get radiologsts but none are online, so display Luke for now
            if (
                this.usertype == "radiologists" &&
                Array.isArray(userlist) &&
                userlist.length === 0
            ) {
                userlist = this.$store.getters.getRadiologists.filter(function (user) {
                    return user.username == "lukeroller";
                });
            }

            // Default of 0 means no max
            if (this.maxResults !== 0) {
                userlist = userlist.slice(0, this.maxResults);
            }

            return userlist;
        },
    },
    methods: {
        approveUser(user) {
            this.axios({
                method: "post",
                url: "/api/v1/users.setActiveStatus",
                headers: {
                    "Content-Type": "application/json",
                    "X-Auth-Token": this.$store.state.authToken,
                    "X-User-Id": this.$store.state.userId,
                },
                data: {
                    activeStatus: true,
                    userId: user._id,
                },
            }).then((response) => {
                if (response.data.success) {
                    this.$store.dispatch("refreshUsers");
                    this.$parent.fetchApprovalUsers();
                }
            });
        },
        isRadiologist() {
            return this.$store.getters.isRadiologist;
        },
        rejectUser(user) {
            this.axios({
                method: "post",
                url: "/api/v1/users.delete",
                headers: {
                    "Content-Type": "application/json",
                    "X-Auth-Token": this.$store.state.authToken,
                    "X-User-Id": this.$store.state.userId,
                },
                data: {
                    userId: user._id,
                },
            }).then((response) => {
                if (response.data.success) {
                    this.$store.dispatch("refreshUsers");
                    this.$parent.fetchApprovalUsers();
                }
            });
        },
        openTextChat(user) {
            let chat_window = this.$store.getters.getTextChatWindowByUsername(user.username);
            if (typeof chat_window !== "undefined") {
                let recent_message = this.$store.state.recent_messages.find((message) => {
                    message.from_user_username == user.username;
                });
                this.$store.dispatch("markRoomRead", { room_id: recent_message.room_id });
                this.$store.commit("openTextChatWindow", user.username);
            } else {
                let textChatObj = {
                    x: this.$store.state.text_chat_window_next_xy,
                    y: this.$store.state.text_chat_window_next_xy,
                    h: 500,
                    w: 500,
                    title: "Chat with " + user.name,
                    username: user.username,
                    start_open: true,
                    is_visible: true,
                    show_button: false,
                };
                this.$store.commit("addTextChatWindow", textChatObj);
            }
        },
        getFieldValue(field, user) {
            if (field == "name") {
                return user.name;
            } else if (field == "status") {
                return user.status;
            } else if (field == "email") {
                if (typeof user.emails !== "undefined") {
                    return user.emails[0].address;
                } else {
                    return "";
                }
            } else if (field == "statusText") {
                return user.statusText;
            } else if (field == "location") {
                if (typeof user.customFields.location === "undefined") {
                    return "";
                } else {
                    return user.customFields.location;
                }
            } else if (field == "phone") {
                if (typeof user.customFields.phone === "undefined") {
                    return "N/A";
                } else {
                    if (user.customFields.phone == "") {
                        return "N/A";
                    } else {
                        return user.customFields.phone;
                    }
                }
            } else if (field == "altphone") {
                if (typeof user.customFields.altphone === "undefined") {
                    return "N/A";
                } else {
                    if (user.customFields.altphone == "") {
                        return "N/A";
                    } else {
                        return user.customFields.altphone;
                    }
                }
            } else if (field == "pager") {
                if (typeof user.customFields.pager === "undefined") {
                    return "N/A";
                } else {
                    if (user.customFields.pager == "") {
                        return "N/A";
                    } else {
                        return user.customFields.pager;
                    }
                }
            } else {
                return "field invalid";
            }
        },
        getFieldColor(field, message) {
            if (field == "statusText") {
                if (message == "At the Hospital - Reading All Studies") {
                    return "green";
                } else if (message == "Not at the Hospital - Reading CT/MR/US/NM Only") {
                    return "yellow";
                } else {
                    return "red";
                }
            } else {
                return "";
            }
        },
    },
};
</script>

<style scoped>
.table-header {
    text-align: center;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 5px;
    color: #ccc;
    font-weight: bolder;
    text-decoration: underline;
}

.user-list table {
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;
    font-size: 14px;
    text-align: left;
}

.user-list table thead {
    font-size: 14px;
    font-weight: bold;
    border-bottom: 1px solid white;
    text-align: center;
}

.user-list table td,
.user-list table thead td {
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 8px;
    padding-right: 8px;
}

.user-phone,
.user-altphone,
.user-pager {
    font-family: "Lucida Console", Monaco, monospace;
}

.user-list table tr:nth-child(odd):not(.noBackground) {
    background: #151515;
}

.user-list table tr:nth-child(even):not(.noBackground) {
    background: #202020;
}

td.user-status {
    text-align: center;
}

.email-link,
.call-link {
    margin-left: 5px;
}

tr.online td.user-status,
.green {
    color: #00ff99;
}

.yellow {
    color: #f1f35f;
}

tr.offline td.user-status,
.red {
    color: #e04545;
}

a {
    color: #5af;
}

.approve-button,
.reject-button {
    cursor: pointer;
    border: none;
    border-radius: 4px;
    box-shadow: none;
    text-shadow: none;
    outline: none;
    text-align: center;
}

.approve-button,
.reject-button {
    background: none;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.approve-button {
    color: #00ff99;
    border: 1px solid #00ff99;
}

.reject-button {
    color: #e04545;
    border: 1px solid #e04545;
}

.approve-button:hover,
.reject-button:hover {
    opacity: 0.8;
}

.approve-button:active,
.reject-button:active {
    transform: translateY(1px);
}

.approve-button:focus,
.reject-button:focus {
    outline: none;
}

.approve-button::-moz-focus-inner,
.reject-button::-moz-focus-inner {
    border: 0;
}

.chat-button {
    cursor: pointer;
    background: #214a80;
    border: none;
    border-radius: 5px;
    padding: 5px;
    box-shadow: none;
    text-shadow: none;
    outline: none;
    color: #fff;
    font-size: 12px;
}

.chat-button:hover {
    opacity: 0.8;
}

.chat-button:active {
    transform: translateY(1px);
}

.chat-button:focus {
    outline: none;
}

.chat-button::-moz-focus-inner {
    border: 0;
}

.noBackground {
    background: rgba(0, 0, 0, 0);
}

@media all and (max-width: 1900px) {
    .table-header {
        font-size: 14px;
    }

    .user-list table {
        font-size: 12px;
    }

    .user-list table thead {
        font-size: 12px;
    }

    .user-list table td,
    .user-list table thead td {
        padding-left: 6px;
        padding-right: 6px;
    }
}

@media all and (max-width: 1600px) {
    .table-header {
        font-size: 12px;
    }

    .user-list table {
        font-size: 10px;
    }

    .user-list table thead {
        font-size: 10px;
    }

    .chat-button {
        font-size: 10px;
    }
    .user-list table td,
    .user-list table thead td {
        padding-left: 4px;
        padding-right: 4px;
    }
}
</style>
