<template>
    <div class="videobutton-container">
        <div v-if="displayError" class="display-error-overlay">
            <div v-if="displayError" class="display-error">
                <span class="closebtn" v-on:click="closeError()">&times;</span>
                {{ displayError }}
            </div>
        </div>
        <button class="chat-button" v-on:click="requestVideoChat()">
            {{ buttonText }}
        </button>
    </div>
</template>

<script>
export default {
    name: "VideoChatButton",
    props: {
        userID: {
            type: String,
            required: true,
        },
        buttonText: {
            type: String,
            default: "Request Video Chat",
        },
        messageToUser: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            displayError: false,
        };
    },
    computed: {
        userObject() {
            return this.$store.getters.getUserObjectById(this.userID);
        },
        messageRecipient() {
            if (this.messageToUser == "") {
                return this.userObject.username;
            } else {
                return this.messageToUser;
            }
        },
    },
    methods: {
        closeError() {
            this.displayError = false;
        },
        requestVideoChat() {
            this.axios({
                method: "post",
                url: "https://api.bra-communicator.com/get-zoom-link",
                headers: {
                    "Content-Type": "application/json",
                },
                data: {
                    userId: this.$store.state.userId,
                    authToken: this.$store.state.authToken,
                    rad_username: this.userObject.username,
                },
            }).then((zoom_link_resp) => {
                if (zoom_link_resp.data.success && zoom_link_resp.data.zoom_link) {
                    this.axios({
                        method: "post",
                        url: "https://api.bra-communicator.com/video-chat-request",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        data: {
                            userId: this.$store.state.userId,
                            authToken: this.$store.state.authToken,
                            to_user_username: this.messageRecipient,
                            from_user_username: this.$store.state.userName,
                            from_user_fullname: this.$store.state.name,
                        },
                    });
                    this.axios({
                        method: "post",
                        url: "/api/v1/chat.postMessage",
                        headers: {
                            "Content-Type": "application/json",
                            "X-Auth-Token": this.$store.state.authToken,
                            "X-User-Id": this.$store.state.userId,
                        },
                        data: {
                            channel: "@" + this.messageRecipient,
                            text:
                                this.$store.state.name +
                                " (phone # " +
                                this.$store.state.phone +
                                ") has requested a video chat session: [click here to open](" +
                                zoom_link_resp.data.zoom_link +
                                ")",
                        },
                    }).then((rc_resp) => {
                        if (rc_resp.data.success) {
                            let a = document.createElement("a");
                            a.target = "_blank";
                            a.href = zoom_link_resp.data.zoom_link;
                            a.click();
                        }
                    });
                } else if (!zoom_link_resp.data.zoom_link) {
                    this.displayError =
                        "[" +
                        this.userObject.name +
                        "] does not have a Zoom account setup yet.";
                }
            });
        },
    },
};
</script>

<style scoped>
.videobutton-container {
    display: inline-block;
}

.chat-button {
    cursor: pointer;
    background: #214a80;
    border: none;
    border-radius: 5px;
    padding: 5px;
    box-shadow: none;
    text-shadow: none;
    outline: none;
    color: #fff;
    font-size: 12px;
}

.chat-button:hover {
    opacity: 0.8;
}

.chat-button:active {
    transform: translateY(1px);
}

.chat-button:focus {
    outline: none;
}

.chat-button::-moz-focus-inner {
    border: 0;
}

.display-error-overlay {
    position: absolute;
    z-index: 9999;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.display-error {
    font-size: large;
    margin: auto;
    padding: 20px;
    background-color: #e04545;
    color: #fff;
    border-radius: 4px;
}

.closebtn {
    margin-left: 25px;
    color: #fff;
    font-weight: bold;
    float: right;
    font-size: 30px;
    line-height: 20px;
    cursor: pointer;
    transition: 0.3s;
}

.closebtn:hover {
    color: black;
}

@media all and (max-width: 1600px) {
    .chat-button {
        font-size: 10px;
    }
}
</style>
