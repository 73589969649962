<template>
    <div class="textchat-container">
        <button class="textchat-button" v-on:click="openWindow()" v-if="showButton">
            {{ buttonText }}
        </button>
        <div class="textchat-area" v-if="window_open" v-show="isVisible">
            <vue-draggable-resizable
                :w="initW"
                :h="initH"
                :minWidth="400"
                :minHeight="400"
                :x="initX"
                :y="initY"
                :parent="true"
                class-name="textchat-window"
                :drag-handle="'.textchat-title'"
            >
                <div class="textchat-topbar">
                    <div class="textchat-title">{{ title }}</div>
                    <div class="textchat-close" v-on:click="closeWindow()">X</div>
                </div>
                <iframe
                    class="textchat-frame"
                    :id="'frame_' + username"
                    :src="getWindowSrc()"
                ></iframe>
            </vue-draggable-resizable>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import VueDraggableResizable from "vue-draggable-resizable";
import "vue-draggable-resizable/dist/VueDraggableResizable.css";
Vue.component("vue-draggable-resizable", VueDraggableResizable);
export default {
    components: VueDraggableResizable,
    name: "TextChatWindow",
    data() {
        return {
            window_open: false,
        };
    },
    props: {
        title: {
            type: String,
            default: "Text Chat",
        },
        username: {
            type: String,
        },
        startOpen: {
            type: Boolean,
            default: true,
        },
        isVisible: {
            type: Boolean,
            default: false,
        },
        showButton: {
            type: Boolean,
            default: true,
        },
        buttonText: {
            type: String,
            default: "Text Chat",
        },
        initX: {
            type: Number,
            default: 20,
        },
        initY: {
            type: Number,
            default: 20,
        },
        initW: {
            type: Number,
            default: 500,
        },
        initH: {
            type: Number,
            default: 500,
        },
    },
    created() {
        if (this.startOpen) {
            this.window_open = true;
        }
    },
    methods: {
        openWindow: function () {
            this.window_open = true;
            this.isVisible = true;
        },
        closeWindow: function () {
            this.window_open = false;
            this.$store.commit("removeTextChatWindow", this.username);
        },
        getWindowSrc: function () {
            return (
                "https://chat.bra-communicator.com/direct/" +
                this.username +
                "?layout=embedded"
            );
        },
    },
};
</script>

<style scoped>
.textchat-area {
    height: calc(100vh - 60px); /* room for the navbar */
    width: 100vw;
    position: fixed;
    top: 50px;
    left: 0px;
    pointer-events: none;
}

.textchat-window {
    background: #333333;
    border: 2px solid #fff;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    pointer-events: all;
    -webkit-box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.75);
}

.textchat-topbar,
.textchat-title,
.textchat-close {
    height: 30px;
}

.textchat-topbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background: #2f343d;
    color: #ddd;
    cursor: pointer;
}

.textchat-title {
    display: flex;
    width: 100%;
    align-items: center;
    padding-left: 10px;
}

.textchat-close {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    font-size: larger;
    font-style: normal;
    color: #000;
    background: #aaa;
}

.textchat-close:hover {
    background: #ddd;
}

.textchat-frame {
    flex-grow: 1;
    border: none;
    margin: 0;
    padding: 0;
}

.textchat-button {
    cursor: pointer;
    background: #214a80;
    border: none;
    border-radius: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 10px;
    padding-left: 10px;
    box-shadow: none;
    text-shadow: none;
    outline: none;
    color: #fff;
}

.textchat-button:hover {
    opacity: 0.8;
}

.textchat-button:active {
    transform: translateY(1px);
}

.textchat-button:focus {
    outline: none;
}

.textchat-button::-moz-focus-inner {
    border: 0;
}
</style>
