import Vue from "vue";
import VueRouter from "vue-router";
import Home from "./views/Home.vue";
import Login from "./views/Login.vue";
import ForgotPassword from "./views/ForgotPassword.vue";
import Register from "./views/Register.vue";
import Files from "./views/Files.vue";
import Profile from "./views/Profile.vue";
import About from "./views/About.vue";
import Reference from "./views/Reference.vue";
import Support from "./views/Support.vue";
import PeerReview from "./views/PeerReview.vue";
import QCMetrics from "./views/QCMetrics.vue";
import FMOAudits from "./views/FMOAudits.vue";
import UserDirectory from "./views/UserDirectory.vue";
import store from "./store.js";
import axios from "axios";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "home",
        component: Home,
    },
    {
        path: "/files",
        name: "files",
        component: Files,
    },
    {
        path: "/user-directory",
        name: "user-directory",
        component: UserDirectory,
    },
    {
        path: "/profile",
        name: "profile",
        component: Profile,
    },
    {
        path: "/forgot-password",
        name: "forgot-password",
        component: ForgotPassword,
    },
    {
        path: "/login",
        name: "login",
        component: Login,
    },
    {
        path: "/register",
        name: "register",
        component: Register,
    },
    {
        path: "/about",
        name: "about",
        component: About,
    },
    {
        path: "/support",
        name: "support",
        component: Support,
    },
    {
        path: "/reference",
        name: "reference",
        component: Reference,
    },
    {
        path: "/peer-review",
        name: "peer-review",
        component: PeerReview,
    },
    {
        path: "/qc-metrics",
        name: "qc-metrics",
        component: QCMetrics,
    },
    {
        path: "/fmo-audits",
        name: "fmo-audits",
        component: FMOAudits,
    },
    {
        path: "/logout",
        name: "logout",
        beforeEnter: (to, from, next) => {
            axios({
                method: "post",
                url: "https://chat.bra-communicator.com/api/v1/logout",
                headers: {
                    "Content-Type": "application/json",
                    "X-Auth-Token": store.state.authToken,
                    "X-User-Id": store.state.userId,
                },
            });
            window.localStorage.removeItem("bra-comm");
            store.commit("resetState");
            store.commit("setLoginFormMessage", {
                message: "Logged Out Successfully",
                error: false,
            });
            next("/login");
        },
    },
    {
        path: "/chat",
        name: "chat",
        beforeEnter: () => {
            let a = document.createElement("a");
            a.target = "_blank";
            a.href = "https://chat.bra-communicator.com/home";
            /* if (store.state.authToken !== "") {
        a.href = "https://chat.bra-communicator.com/home?resumeToken=" + store.state.authToken;
      } */
            a.click();
        },
    },
    {
        path: "/video-chat",
        name: "video-chat",
        beforeEnter: () => {
            let a = document.createElement("a");
            a.target = "_blank";
            a.href = "https://us02web.zoom.us/";
            a.click();
        },
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;

router.beforeEach((to, from, next) => {
    if (
        !store.getters.isAuthenticated &&
        to.path !== "/login" &&
        to.path !== "/register" &&
        to.path !== "/forgot-password" &&
        to.path !== "/support"
    ) {
        next("/login");
    } else {
        next();
    }
});
